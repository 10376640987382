<template>
        <base-page>
        <titlu-pagina Titlu="Checklist" @on_add_clicked="show_dialog()" :AdaugaVisible="$has_right('adaugare-checklist')"/>
        <el-card style='margin:5px 0px 5px 0px'>
            <div slot="header" class="clearfix">
                <strong> Filtre </strong>
            </div>
            <div class="filtre">
                <el-form @submit.prevent.native='refresh_info()'>
                    <el-row :gutter="20">
                        
                        <el-col :md='4'>
                            <el-form-item label='Client' >
                                <el-select class='full-width' v-model='Filters.CustomerId' >
                                    <el-option label='Toate' value='-1'></el-option>
                                    <el-option v-for='item in Info.clienti' :key="'clienti' + item.Id" :label='item.Nume' :value='item.Id'></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :md='4'>
                            <el-form-item label='Masina' >
                                <el-select class='full-width' v-model='Filters.IdMasina' >
                                    <el-option label='Toate' value='-1'></el-option>
                                    <el-option v-for='item in Info.masini' :key="'masini' + item.Id" :label='item.Numar' :value='item.Id'></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :md='4'>
                            <el-form-item label='Data' >
                                <el-date-picker v-model='Filters.Data' type='datetime' value-format='yyyy-MM-dd HH:mm:ss' format='dd.MM.yyyy HH:mm' />
                            </el-form-item>
                        </el-col>
                        <el-col :md='4'>
                            <el-form-item label='Status' >
                                <el-select class='full-width' v-model='Filters.Status' >
                                    <el-option label='Toate' value='-1'></el-option>
                                    <el-option label='in lucru' value='in_lucru'></el-option>
                                    <el-option label='finalizat' value='finalizat'></el-option>
                                    <el-option label='alocat' value='alocat'></el-option>
                                    <el-option label='ofertat respins' value='ofertat_respins'></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>                        
                        <el-col :md='24' >
                            <el-button type='primary' native-type='submit' @click='refresh_info()'> Aplica </el-button>
                            <el-button @click='reset'> Reset </el-button>
                        </el-col>

                    </el-row>
                </el-form>
                </div>
        </el-card>

        <el-table :data="Results" >
            <el-table-column prop='Client' label='Client'></el-table-column>
            <el-table-column prop='Masina' label='Masina'></el-table-column>
            <el-table-column label='Data'>
                <template slot-scope='scope'>
                    {{ scope.row.Data | momentformat('dd.MM.YYYY HH:mm') }} 
                </template>
            </el-table-column>
            <el-table-column prop='Status' label='Status'></el-table-column>
            <el-table-column fixed="right" label="Actiuni" width="200px" >
                <template slot-scope="scope" >

                    <el-tooltip v-if="$has_right('modificare-checklist')" content="Modificare">
                        <el-button type="primary" icon="el-icon-edit"  circle @click="show_dialog(scope.row.Id)" />
                    </el-tooltip>

                    <el-tooltip v-if="$has_right('stergere-checklist')" content="Sterge" >
                        <el-button type="danger" icon="el-icon-delete" circle @click="delete_item(scope.row)" />
                    </el-tooltip>

                </template>
        </el-table-column>
        </el-table>
<el-pagination @size-change="refresh_info" @current-change= "refresh_info" :page-size.sync="PaginationInfo.PerPage" :current-page.sync="PaginationInfo.Page" :total="PaginationInfo.RowCount" layout="pager" />
<Checklist-dialog ref='dlg' @save="refresh_info()" />
</base-page>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import Checklist_dialog from '@/pages/checklist/Checklist_dialog.vue';
import TitluPagina from '@/widgets/TitluPagina';

export default {
    name: "checklist",
    extends: BasePage,
    components: {
        'base-page': BasePage,
        'Checklist-dialog': Checklist_dialog,
        'titlu-pagina': TitluPagina
    },
    data () {
        return {
            Results: [],
            base_url: '',
            Info: {
                clienti: [], masini: [],             },
            Filters: {
                CustomerId: '-1' , IdMasina: '-1' , Data: '' , Status: '-1' ,             },
            OrderBy: { },
            PaginationInfo: { Page: 1, PerPage: 50, RowCount: 0, PageSizes: [10, 25, 50, 100, 200] },
        }
    },
    methods: {

        async get_info(){
            if (!this.$has_right('vizualizare-checklist')){
                this.$router.push('/no-rights')
                return
            }
            var response        = await this.post("checklist/get_info" );
            this.Info.clienti = response.clienti;
            //if( this.Filters.CustomerId.length > 0 ) this.Filters.CustomerId = this.Info.clienti[0].Id;
            this.Info.masini = response.masini;
            //if( this.Filters.IdMasina.length > 0 ) this.Filters.IdMasina = this.Info.masini[0].Id;
            this.refresh_info();
        },

        async refresh_info(){
            var response        = await this.post("checklist/index", { Filters: this.Filters, OrderBy: this.OrderBy, PaginationInfo: this.PaginationInfo } );
            this.Results        = response.Results;
            this.PaginationInfo = response.PaginationInfo;
            //
            this.select_menu_item('checklist');
        },
        reset(){
            this.Filters = {
                CustomerId: '-1' , IdMasina: '-1' , Data: '' , Status: '-1' ,             };
            this.refresh_info();
        },
        async delete_item( item ){            
            this.$confirm(`Sunteti sigur ?`, 'Warning', {               
                type: 'warning'
                }).then(async() => {
                    await this.post("checklist/delete_item", { id: item.Id } );
                    this.refresh_info(); 
                    this.$message({type: 'info', message: 'Stergere efectuata cu succes'});                                                                       
                    }).catch(() => {
                        this.$message({type: 'info', message: 'Stergere anulata'});                                                                 
                        });
        },
        show_dialog(id){
            this.$refs.dlg.show_me(id);
        }
    },
    mounted(){
        this.base_url = settings.BASE_URL;
        this.get_info();
    }
};
</script>

<style lang="less" scoped>

    .top50{
        margin-top: 20px;
    }

    .filtre{
        .el-input-number
        {
            width: 100% !important;
        }
    }

</style>
