<template>
    <el-dialog title="Checklist" :visible.sync="showPopup" class="my-dialog-class" :show-close='false' :close-on-click-modal='false'>
        <el-form label-position="top" :inline="false" :rules="rules" label-width="100%"  :model='selectedObject'  ref='my-form' @submit.prevent="save" v-loading="loadingVisible" >
            <el-row :gutter="15" >
                
                <el-col :md='8'>
                    <el-form-item label='Client' prop="CustomerId">
                        <el-select v-model='selectedObject.CustomerId'  class='full-width'><el-option label='Fara' value='-1'></el-option>        <el-option v-for='item in Info.clienti' :key="'clienti' + item.Id" :label='item.Nume' :value='item.Id'></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :md='8'>
                    <el-form-item label='Masina' prop="IdMasina">
                        <el-select v-model='selectedObject.IdMasina'  class='full-width'><el-option label='Fara' value='-1'></el-option>        <el-option v-for='item in Info.masini' :key="'masini' + item.Id" :label='item.Numar' :value='item.Id'></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :md='8'>
                    <el-form-item label='Data' prop="Data">
                        <el-date-picker  class='full-width' v-model='selectedObject.Data' type='datetime' value-format='yyyy-MM-dd HH:mm:ss' format='dd.MM.yyyy HH:mm' />
                    </el-form-item>
                </el-col>
                <el-col :md='8'>
                    <el-form-item label='Status' prop="Status">
                        <el-select class='full-width' v-model='selectedObject.Status' >
                            <el-option label='Fara' value='-1'></el-option>
                            <el-option label='in lucru' value='in_lucru'></el-option>
                            <el-option label='finalizat' value='finalizat'></el-option>
                            <el-option label='alocat' value='alocat'></el-option>
                            <el-option label='ofertat respins' value='ofertat_respins'></el-option>
                        </el-select>
                    </el-form-item>
                </el-col> 

            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer" >
            <el-button @click="resetForm('my-form')"           > Renunta  </el-button>
			<el-button type="primary" @click="save('my-form')" > Salveaza </el-button>
        </span>
    </el-dialog>
</template>

<script>
    import settings from "@/backend/LocalSettings";
    import BasePage from '@/pages/BasePage';

    export default {
        name: "Checklist_dialog",
        extends: BasePage,
        data () {
            return {
                showPopup: false,
                mode: 'add',
                selectedObject: {
                    CustomerId: '-1' , IdMasina: '-1' , Data: '' , Status: '-1' ,                 },
                Info:{
                    clienti: [], masini: [],                 },
                rules: {
                    CustomerId: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    IdMasina: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    Data: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    Status: [ { required: true, message: 'Campul este obligatoriu' } ], 
                }
            }
        },
        methods: {
            show_me: async function( id ) {
                this.showPopup        = true;
                if( id == null )
                {
                    this.mode = "add";
                }
                else
                {
                    this.mode             = "edit";
                    var            result = await this.post("checklist/get_info_item_dialog", { id: id } );
                    this.selectedObject   = result.Item;
                }
            },
            async get_info(){
                var response = await this.post("checklist/get_info_for_dialog" );
                this.Info.clienti = response.clienti;this.Info.masini = response.masini;            
            },            
            save: async function() {
                this.$refs['my-form'].validate( async(valid) => {
                    if (valid)
                    {
                        await this.post("checklist/save", { mode: this.mode, object: this.selectedObject } );
                        this.resetForm()
                        this.$emit("save");
                    } else console.log('eroare validare formular!')
                });
            },            
			resetForm() {
				this.$refs['my-form'].resetFields()
				this.showPopup = false
			}
        },
        mounted: function() {
            this.get_info();
        }
    };
</script>

<style lang="less" scoped>
    .full-width {
        width: 100%;
    }

</style>